import React, {useEffect, useState} from 'react'
import {GetHighScores} from "../../api/GetHighScores";
import {JWTTokenCookieName} from "../../cookies";
import {useCookies} from "react-cookie";
import axios from "axios";
import {browserHistory} from "../../browserhistory";
import {RFC3339ToEasyDate} from "../../utils/TimeFormatting";
import {GetConfigurationAxiosConfig} from "../../api/GetConfigurationAxiosConfig";
import {Selector} from "../../components/selector/Selector";

export function HighScorePage(props) {
    const [cookies] = useCookies([JWTTokenCookieName])
    const configID = props.match.params.config_id
    const [highScores, setHighScores] = useState([])
    const [configuration, setConfigurations] = useState(null)

    const [selectedTimeframeItemIndex, setSelectedTimeframeItem] = useState(0)
    const [selectedScoreTypeIndex, setSelectedScoreTypeIndex] = useState(0)

    useEffect(() => {
        const timeFrames = ['last_30_days', undefined]
        const scoreTypes = ['single_attempt', 'average']

        const config = GetHighScores(configID, timeFrames[selectedTimeframeItemIndex], scoreTypes[selectedScoreTypeIndex], cookies[JWTTokenCookieName])
        axios(config).then((response) => {
            setHighScores(response.data)
        }).catch((err) => {
            console.error(err)
        })
    }, [selectedTimeframeItemIndex, selectedScoreTypeIndex])

    useEffect(() => {
        const config = GetConfigurationAxiosConfig(configID, cookies[JWTTokenCookieName])
        axios(config).then((response) => {
            setConfigurations(response.data)
        }).catch((err) => {

        })
    }, [])

    const rows = []

    if (highScores !== null && configuration !== null) {
        for (let i = 0; i < highScores.length; i++) {
            const aHighScore = highScores[i]

            const pos = `${i + 1}.`
            const username = aHighScore.username
            const createdAt = RFC3339ToEasyDate(aHighScore.results_created_at)
            const numberOfCorrectAnswers = aHighScore.number_of_correct_answers ? aHighScore.number_of_correct_answers : aHighScore.avg_number_of_correct_answers
            const sToComplete = aHighScore.time_to_complete_ms ? aHighScore.time_to_complete_ms / 1000 : aHighScore.avg_time_to_complete / 1000

            rows.push(
                <tr>
                    <td>{pos}</td>
                    <td>{username}</td>
                    {aHighScore.results_created_at && <td className={'txt-align-center'}>{createdAt}</td>}
                    <td className={'txt-align-center'}>{`${numberOfCorrectAnswers}/${configuration.number_of_questions}`}</td>
                    <td className={'txt-align-center'}>{`${sToComplete}s`}</td>
                </tr>
            )
        }
    }

    return <>
        <img src={'/qm-logo-corner.svg'} className={'qm-logo-top-left'} onClick={() => browserHistory.push('/home')}/>


        <h2 className={'mid-header w-100-pt txt-align-center mg-t-108'}>{configuration ? configuration.name : ''} High Scores</h2>

        <div className={'pad-20 mg-t-22 mg-l-20 mg-r-20'}>
            <Selector items={[{title: 'Last 30 Days'}, {title: 'All Time'}]}
                      selectedItem={selectedTimeframeItemIndex} onItemClicked={(i) => setSelectedTimeframeItem(i)}/>

            <Selector className={'mg-t-22'} items={[{title: '1-Game Score'}, {title: 'Last 10-Game Avg.'}]}
                      selectedItem={selectedScoreTypeIndex} onItemClicked={(i) => setSelectedScoreTypeIndex(i)}/>

            <table className={'high-scores-table pad-10 w-100-pt mg-t-53'}>
                {rows}
            </table>
        </div>

        <div>
            <button className={'large-blue-button mg-t-55 mg-0-auto'}
                    onClick={() => browserHistory.push('/home')}>Home
            </button>
        </div>
    </>
}