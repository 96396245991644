import {useEffect, useState} from "react";
import {GetConfigurationAxiosConfig} from "../../api/GetConfigurationAxiosConfig";
import {useCookies} from "react-cookie";
import {JWTTokenCookieName} from "../../cookies";
import {browserHistory} from "../../browserhistory";
import axios from "axios";
import {GetGenerateQuestionSetAxiosConfig} from "../../api/GetGenerateQuestionSetAxiosConfig";

export function GenerateQuestionSet(props) {
    const [errorMessage, setErrorMessage] = useState(null)
    const configID = props.match.params.config_id

    const [cookies] = useCookies([JWTTokenCookieName])

    if (!cookies[JWTTokenCookieName]) {
        browserHistory.push('/welcome')
    }

    useEffect(() => {
        const config = GetConfigurationAxiosConfig(configID, cookies[JWTTokenCookieName])
        axios(config).then((response) => {
            const configID = response.data.id

            const config2 = GetGenerateQuestionSetAxiosConfig(configID, cookies[JWTTokenCookieName])
            axios(config2).then((response) => {
                localStorage.setItem('current_question_set', JSON.stringify(response.data));
                localStorage.setItem('current_question_set_progress', JSON.stringify([]))
                browserHistory.push(`/solve-question-set/${response.data.question_set.id}`)
            }).catch((e) => {
                console.error(e)
                setErrorMessage('something went wrong')
            })

        }).catch((e) => {
            console.error(e)
            setErrorMessage('something went wrong')
        })
    }, [configID])

    return <>
        {errorMessage ? errorMessage : 'Loading...'}
    </>
}