import moment from "moment";

export function RFC3339ToEasyDate(time) {
    const m = moment(time);

    return m.format('MMM DD, YYYY')
}

export function RFC3339ToEasyTime(time) {
    const m = moment(time);

    return m.format('hh:mm a')
}

export function RFC3339ToEasyDateTime(time) {
    const m = moment(time);

    return m.format('MMM DD, YYYY [at] hh:mm a')
}
