import React from 'react'
import './Banner.css'

export function Banner(props) {
    const text = props.text ? props.text : ''
    const color = props.negative ? '#FF006E' : '#3A86FF'

    return <div hidden={props.hidden} style={{background: color}} className={'banner-parent'}>
        {text}
    </div>
}