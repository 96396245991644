import React, {useEffect, useState} from "react";
import {browserHistory} from "../../browserhistory";
import {GetResultByIDAxiosConfig} from "../../api/GetResultByIDAxiosConfig";
import {useCookies} from "react-cookie";
import {JWTTokenCookieName} from "../../cookies";
import axios from "axios";
import {AddCommasToNumber} from "../../utils/AddCommasToNumber";


export function ViewResultsPage(props) {
    const [cookies] = useCookies([JWTTokenCookieName])
    const resultID = props.match.params.result_id
    const [result, setResult] = useState(null)
    const [configuration, setConfiguration] = useState(null)

    useEffect(() => {
        const config = GetResultByIDAxiosConfig(resultID, cookies[JWTTokenCookieName])
        axios(config).then((response) => {
            setResult(response.data.result)
            setConfiguration(response.data.configuration)
        }).catch((e) => {
            console.error(e)
        })
    }, [resultID])

    const correctAnswers = result !== null ? result.number_of_correct_answers : '...'
    const numberOfQuestions = configuration !== null ? `/${configuration.number_of_questions}` : ''
    const timeToComplete = result !== null ? result.time_to_complete_ms / 1000 : ''

    return <>
        <img src={'/qm-logo-corner.svg'} className={'qm-logo-top-left'} onClick={() => browserHistory.push('/home')}/>

        <div className={'round-yellow-circle-center-container z-n1'}>
            <div className={'round-yellow-circle-center'}>

            </div>
        </div>

        <h2 className={'mid-header w-100-pt txt-align-center mg-t-150'}>Finito!</h2>

        <div className={'question-solver-current-input mg-t-22'}>
            {`${correctAnswers}${numberOfQuestions}`}
        </div>

        <div className={'correct-answers-label txt-align-center'}>
            Correct Answers
        </div>

        <div className={'question-solver-current-input mg-t-22'}>
            {timeToComplete}
        </div>

        <div className={'correct-answers-label txt-align-center'}>
            Time to Solve (s)
        </div>

        <div>
            <button className={'large-blue-button mg-t-55 mg-0-auto'} onClick={() => browserHistory.push('/home')}>Home</button>
            <button className={'large-orange-button mg-t-41 mg-0-auto'} onClick={() => browserHistory.push(`/highscore/${configuration.id}`)}>Highscores</button>
            <button className={'large-yellow-button mg-t-41 mg-0-auto mg-b-80'} onClick={() => browserHistory.push(`/confirm-game/${configuration.id}`)}>Play Again</button>
        </div>
    </>
}