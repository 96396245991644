import React, {useState} from 'react'
import './Selector.css'

Selector.defaultProps = {
    items: [],
    selectedItem: 0,
    onItemClicked: (index) => {},
}


export function Selector(props) {
    const selections = []

    for (let i = 0; i < props.items.length; i++) {
        const item = props.items[i]
        const title = item.title

        const className = props.selectedItem !== i ? 'selection' : 'active-selection'

        const onClick = () => {
            props.onItemClicked(i)
        }

        selections.push(<span className={className} onClick={onClick}>{title}</span>)
    }

    const className = ['selector-container', props.className].join(' ')

    return <div className={className}>
        {selections}
    </div>
}