import {BackendBaseURL} from "../envvar";


export function GetResultByIDAxiosConfig(resultID, jwtToken) {
    return {
        baseURL: BackendBaseURL,
        url: '/result',
        method: 'get',
        headers: {
            Authorization: `Bearer ${jwtToken}`
        },
        params: {
            id: resultID,
        }
    }
}