import React, {useEffect, useState} from 'react'
import {browserHistory} from "../../browserhistory";
import {useCookies} from "react-cookie";
import {JWTTokenCookieName} from "../../cookies";
import {GetNonCustomConfigurationAxiosConfig} from "../../api/GetNonCustomConfigurationAxiosConfig";
import axios from "axios";

export function GameSelectorPage() {
    const [cookies] = useCookies([JWTTokenCookieName])
    const [nonCustomConfigs, setNonCustomConfigs] = useState([])

    useEffect(() => {
        const config = GetNonCustomConfigurationAxiosConfig(cookies[JWTTokenCookieName])
        axios(config).then((response) => {
            setNonCustomConfigs(response.data)
        }).catch((e) => {
            console.error(e)
        })
    }, [])

    if (!cookies[JWTTokenCookieName]) {
        browserHistory.push('/welcome')
    }

    const hasNonCustomConfig = (name) => {
        let has = false

        for (let i = 0; i < nonCustomConfigs.length; i++) {
            const config = nonCustomConfigs[i]

            if (name === config.name) {
                has = true
            }
        }

        return has
    }

    const onGameTypeSelected = (name) => {
        let has = false

        for (let i = 0; i < nonCustomConfigs.length; i++) {
            const config = nonCustomConfigs[i]

            if (name === config.name) {
                browserHistory.push(`/confirm-game/${config.id}`)
            }
        }

        return has
    }

    return <>
        <img src={'qm-logo-corner.svg'} className={'qm-logo-top-left'} onClick={() => browserHistory.push('/home')}/>

        <h2 className={'mid-header w-100-pt txt-align-center mg-t-108'}>Select Game Type</h2>

        <div className={'mg-t-34 w-100-pt'}>
            <div className={'p-absolute l-20 r-20'}>
                {hasNonCustomConfig('Easy') && <button className={'large-glowing-yellow-button w-100-pt pad-18'} onClick={() => onGameTypeSelected('Easy')}>Easy</button>}
                {hasNonCustomConfig('Medium') && <button className={'large-glowing-orange-button w-100-pt pad-18 mg-t-20'} onClick={() => onGameTypeSelected('Medium')}>Medium</button>}
                {hasNonCustomConfig('Hard') && <button className={'large-glowing-pink-button w-100-pt pad-18 mg-t-20'} onClick={() => onGameTypeSelected('Hard')}>Hard</button>}
                {hasNonCustomConfig('Super Hard') && <button className={'large-glowing-purple-button w-100-pt pad-18 mg-t-20'} onClick={() => onGameTypeSelected('Super Hard')}>Super Hard</button>}
            </div>
        </div>
    </>
}