import React from 'react';
import {browserHistory} from "../../browserhistory";
import {BackendBaseURL} from "../../envvar";
import {useCookies} from "react-cookie";
import {JWTTokenCookieName} from "../../cookies";

export function WelcomePage(props) {
    const [cookies, setCookie, removeCookie] = useCookies([JWTTokenCookieName])

    if (cookies[JWTTokenCookieName]) {
        browserHistory.push('/home')
    }
    return (
        <>
            <img src={'qm-logo.svg'} className={'mg-0-auto'}/>


            <div style={{bottom: 0, position: 'absolute', zIndex: 10, width: '100%'}}>
                <div>
                    <button className={'purple-button w180 mg-0-auto mg-b-23'} onClick={() => browserHistory.push('/login')}>Log In</button>
                </div>
                <div>
                    <button className={'purple-button w180 mg-0-auto mg-b-80'} onClick={() => browserHistory.push('/register')}>Register</button>
                </div>


            </div>
            <RoundYellowCircle/>

        </>
    );
}

function RoundYellowCircle(props) {

    return <div className={'round-yellow-circle-bottom-container'}>
        <div className={'round-yellow-circle-bottom'}>
            <img src={'plus-sign.svg'} className={'mg-b-325 mg-l-28'}/>
            <img src={'minus-sign.svg'} className={'mg-b-415 mg-l-16'}/>
            <img src={'multiplication-sign.svg'} className={'mg-b-386 mg-l-40'}/>
            <img src={'division-sign.svg'} className={'mg-b-318 mg-l-14'}/>
        </div>

    </div>
}