// How to use:
//
//  <input onChange={CreateOnChangeListener(setState)}/>
export function CreateOnChangeListener(setState) {
    return function(event) {
        setState(event.target.value);
    }
}

const decimalRegex = /^[0-9]+(\.[0-9]*)?$/g
export function DecimalOnChangeListener(setState) {
    return function(event) {
        const v = event.target.value
        if (v === '' || v.match(decimalRegex)) {
            setState(v)
        } else {
            event.preventDefault()
        }
    }
}

const integerRegex = /^[0-9]+$/g
export function IntegerOnChangeListener(setState) {
    return function(event) {
        const v = event.target.value
        if (v === '' || v.match(integerRegex)) {
            setState(v)
        } else {
            event.preventDefault()
        }
    }
}