import {browserHistory} from "../../browserhistory";
import {PostQuestionSetResult} from "../../api/PostQuestionSetResult";
import axios from "axios";
import {useCookies} from "react-cookie";
import {JWTTokenCookieName} from "../../cookies";
import {useEffect} from "react";


export function SubmitResultsPage(props) {
    const [cookies] = useCookies([JWTTokenCookieName])

    const questionSet = JSON.parse(localStorage.getItem('current_question_set'))
    const results = JSON.parse(localStorage.getItem('current_question_set_progress'))

    useEffect((() => {
        if (results.length < questionSet.question_set.questions.length) {
            browserHistory.push(`/solve-question-set/${questionSet.question_set.id}`)
        } else {
            const config = PostQuestionSetResult(questionSet.question_set.id, questionSet.configuration.id, results, cookies[JWTTokenCookieName])
            axios(config).then((response) => {
                browserHistory.push(`/view-results/${response.data.id}`)
            }).catch((e) => {
                console.log(e)
            })
        }
    }), [])


    return <></>
}