import {BackendBaseURL} from "../envvar";

export function GetGenerateQuestionSetAxiosConfig(configID, jwtToken) {
    return {
        baseURL: BackendBaseURL,
        url: '/question-set/generate',
        method: 'get',
        params: {
            config_id: configID,
        },
        headers: {
            Authorization: `Bearer ${jwtToken}`
        }
    }
}