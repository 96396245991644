import React, {useState} from 'react'
import {browserHistory} from "../../browserhistory";
import {CreateOnChangeListener} from "../../utils/onchange";
import {Banner} from "../../components/banner/Banner";
import {CreateUserAxiosConfig} from "../../api/createuser";
import axios from "axios";
import {useCookies} from "react-cookie";
import {JWTTokenCookieName} from "../../cookies";

export function RegisterPage(props) {
    const [cookies, setCookie, removeCookie] = useCookies([JWTTokenCookieName])

    if (cookies[JWTTokenCookieName]) {
        browserHistory.push('/home')
    }

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState(null)
    const [submitting, setSubmitting] = useState(false)

    const registrationButtonClicked = (e) => {
        e.preventDefault()

        if (username === '') {
            setErrorMessage('Please enter a username')
        } else if (password === '' || password.length < 6) {
            setErrorMessage('Please enter a password that is at least 6 characters long')
        } else {
            setErrorMessage(null)
            setSubmitting(true)
            const config = CreateUserAxiosConfig(username, password)
            axios(config).then((response) => {
                setCookie(JWTTokenCookieName, response.data.token)
                browserHistory.push('/home')
            }).catch((err) => {
                if (err.response.status === 409) {
                    setErrorMessage('The username is already taken. Try another.')
                }
            }).finally(() => {
                setSubmitting(false)
            })
        }
    }

    return <>
        <Banner text={errorMessage ? errorMessage : ''} negative={true} hidden={errorMessage === null}/>

        <img src={'qm-logo-corner.svg'} className={'qm-logo-top-left'} onClick={() => browserHistory.push('/welcome')}/>


        <div className={'round-green-circle-center-container'}>
            <div className={'round-green-circle-center'}>

            </div>
        </div>

        <div className={'p-absolute t-76 w-100-pt'}>
            <h2 className={'p-absolute mid-header mg-l-20 mg-t-129'}>Register</h2>
            <form className={'p-absolute mg-t-177 l-20 r-20'}>
                <input placeholder={'Username'} type={'username'} className={'black-bordered-input w-100-pt'}
                       value={username} onChange={CreateOnChangeListener(setUsername)} disabled={submitting}/>
                <input placeholder={'Password'} type={'password'} className={'black-bordered-input w-100-pt mg-t-16'}
                       value={password} onChange={CreateOnChangeListener(setPassword)} disabled={submitting}/>
                <input type={'submit'} className={'purple-button w-100-pt mg-t-43'} value={'LET\'S GO!'}
                       onClick={registrationButtonClicked} disabled={submitting}/>
            </form>
        </div>
    </>
}