import React, {useEffect, useState} from "react";
import {AddCommasToNumber} from "../../utils/AddCommasToNumber";
import {browserHistory} from "../../browserhistory";
import moment from "moment";
import {Banner} from "../../components/banner/Banner";

export function GameSolverSolveQuestionSet(props) {
    const [numberInput, setNumberInput] = useState('')
    const questionSet = JSON.parse(localStorage.getItem('current_question_set'))
    const [questionSetProgress, setQuestionSetProgress] = useState(JSON.parse(localStorage.getItem('current_question_set_progress')))
    const [questionStartTime, setQuestionStartTime] = useState(moment())
    const questionSetID = props.match.params.question_set_id
    const [showIncorrectAnswerBanner, setShowIncorrectAnswerBanner] = useState(false)

    useEffect(() => {
        if (questionSet === null || questionSet.question_set.id !== questionSetID) {
            localStorage.removeItem('current_question_set')
            localStorage.removeItem('current_question_set_progress')
            browserHistory.push('/home')
        }
    }, [])

    const opsMap = {
        'add': '+',
        'sub': '-',
        'mul': '×',
        'div': '÷',
    }

    const finishedQuestionSet = questionSetProgress.length === questionSet.question_set.questions.length

    if (finishedQuestionSet) {
        browserHistory.push('/view-results')
    }

    const question = !finishedQuestionSet ? questionSet.question_set.questions[questionSetProgress.length] : 0
    const firstNumber = !finishedQuestionSet ? question.first_number : 0
    const secondNumber = !finishedQuestionSet ? question.second_number : 0
    const operation = !finishedQuestionSet ? opsMap[question.operation] : 0
    const answer = !finishedQuestionSet ? question.answer : null
    const mustAnswerCorrectly = !finishedQuestionSet ? questionSet.configuration.must_answer_correctly : false

    const onAnswerSubmitted = () => {
        if (numberInput !== '') {
            if (mustAnswerCorrectly && Number(numberInput) !== answer) {
                setShowIncorrectAnswerBanner(true)
                setNumberInput('')
            } else {
                const endTime = moment()

                setShowIncorrectAnswerBanner(false)

                const timeToSolveMS = endTime.diff(questionStartTime, 'ms')
                const answer = {
                    answer: numberInput,
                    timeToSolveMS: timeToSolveMS,
                }

                const arr = [...questionSetProgress]
                arr.push(answer)
                setQuestionSetProgress(arr)
                console.log(arr)
                localStorage.setItem('current_question_set_progress', JSON.stringify(arr))
                setNumberInput('')
                setQuestionStartTime(moment())
                if (arr.length === questionSet.question_set.questions.length) {
                    browserHistory.push('/submit-results')
                }
            }
        }
    }

    return <>
        <Banner text={'Wrong answer, please try again!'} negative={true} hidden={!showIncorrectAnswerBanner}/>

        <button className={'p-absolute t-20 l-20 quit-button'} onClick={() => browserHistory.push(`/confirm-game/${questionSet.configuration.id}`)}>
            Quit Game
        </button>

        <div className={'current-question-numbers mg-t-22 mg-0-auto txt-align-right mx-w-100'}>
            <div>{firstNumber}</div>
            <div><span className={'f-left'}>{operation}</span><span>{secondNumber}</span></div>
        </div>

        <hr className={'mx-w-300 border-2-solid'}/>

        <div className={'question-solver-current-input mg-t-12'}>
            {AddCommasToNumber(numberInput)}
        </div>

        <div className={'p-absolute b-45 w-100-pt'}>
            <div className={'grid-container-3-4-60 mg-0-auto'}>
                <button className={'game-solver-number'} onClick={() => setNumberInput(numberInput + '7')}>7</button>
                <button className={'game-solver-number'} onClick={() => setNumberInput(numberInput + '8')}>8</button>
                <button className={'game-solver-number'} onClick={() => setNumberInput(numberInput + '9')}>9</button>

                <button className={'game-solver-number'} onClick={() => setNumberInput(numberInput + '4')}>4</button>
                <button className={'game-solver-number'} onClick={() => setNumberInput(numberInput + '5')}>5</button>
                <button className={'game-solver-number'} onClick={() => setNumberInput(numberInput + '6')}>6</button>

                <button className={'game-solver-number'} onClick={() => setNumberInput(numberInput + '1')}>1</button>
                <button className={'game-solver-number'} onClick={() => setNumberInput(numberInput + '2')}>2</button>
                <button className={'game-solver-number'} onClick={() => setNumberInput(numberInput + '3')}>3</button>

                <button className={'game-solver-number'}/>
                <button className={'game-solver-number'} onClick={() => setNumberInput(numberInput + '0')}>0</button>
                <button className={'game-solver-number'}/>
            </div>
        </div>

        <button className={'p-absolute b-45 l-0 clear-button'} onClick={() => setNumberInput('')}>
            Clear
        </button>

        <button className={'p-absolute b-45 r-0 ok-button'} onClick={onAnswerSubmitted}>
            Ok
        </button>
    </>
}