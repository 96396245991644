import {BackendBaseURL} from "../envvar";

export function GetConfigurationAxiosConfig(configID, jwtToken) {
    return {
        baseURL: BackendBaseURL,
        url: '/configuration',
        method: 'get',
        params: {
            id: configID,
        },
        headers: {
            Authorization: `Bearer ${jwtToken}`
        }
    }
}