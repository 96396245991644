import React, {useEffect, useState} from 'react'
import {browserHistory} from "../../browserhistory";
import {useCookies} from "react-cookie";
import {JWTTokenCookieName} from "../../cookies";
import axios from "axios";
import {GetAuthorizationAxiosConfig} from "../../api/GetAuthorizationAxiosConfig";

export function HomePage() {
    const [cookies, , removeCookie] = useCookies([JWTTokenCookieName])
    const [userInfo, setUserInfo] = useState(null)

    if (!cookies[JWTTokenCookieName]) {
        browserHistory.push('/welcome')
    }

    useEffect(() => {
        const config = GetAuthorizationAxiosConfig(cookies[JWTTokenCookieName])
        axios(config).then((response) => {
            setUserInfo(response.data)
        }).catch(() => {
            removeCookie(JWTTokenCookieName)
            browserHistory.push('/welcome')
        })
    }, [])

    return <>
        <div className={'sign-out-button f-right mg-t-22 mg-r-20 cursor-pointer'} onClick={() => {
            removeCookie(JWTTokenCookieName)
            browserHistory.push('/welcome')
        }}>
            Sign Out
        </div>

        <img src={'/qm-logo-corner.svg'} className={'qm-logo-top-left'} onClick={() => browserHistory.push('/home')}/>


        <div className={'round-yellow-circle-center-container z-0'}>
            <div className={'round-yellow-circle-center'}>

            </div>
        </div>

        <div className={'p-absolute t-76 w-100-pt z-100'}>
            <h2 className={'mid-header w-100-pt txt-align-center mg-t-53'}>{userInfo !== null ? `Hello, ${userInfo.username}!` : ''}</h2>

            <button className={'large-blue-button mg-t-55 mg-0-auto'} onClick={() => browserHistory.push('/new-game')}>New Game</button>
            <button className={'large-orange-button mg-t-41 mg-0-auto'} onClick={() => browserHistory.push('/highscore')}>High Scores</button>
            {/*<button className={'large-yellow-button mg-t-41 mg-0-auto'}>My Friends</button>*/}
        </div>
    </>
}