import './styles/buttons.css';
import './styles/controlled-props.css';
import './styles/geometry.css';
import './styles/inputs.css';
import './styles/images.css';
import './styles/texts.css';
import './styles/table.css';
import {Route, Router, Switch} from "react-router";
import {browserHistory} from "./browserhistory";
import {WelcomePage} from "./pages/welcome/WelcomePage";
import {LoginPage} from "./pages/login/LoginPage";
import {RegisterPage} from "./pages/register/RegisterPage";
import {HomePage} from "./pages/home/HomePage";
import {GameSelectorPage} from "./pages/gameselector/GameSelectorPage";
import {GameSolverSolveQuestionSet} from "./pages/gamesolver/GameSolverSolveQuestionSet";
import {ViewResultsPage} from "./pages/viewresult/ViewResultsPage";
import {SubmitResultsPage} from "./pages/submitresult/SubmitResultsPage";
import {HighScorePage} from "./pages/highscore/HighScorePage";
import {SelectConfigToViewHighScorePage} from "./pages/highscore/SelectConfigToViewHighScorePage";
import {GenerateQuestionSet} from "./pages/generatequestionset/GenerateQuestionSet";
import {ConfirmGamePage} from "./pages/confirmgame/ConfirmGamePage";

export default function App() {
    return (
        <Router history={browserHistory}>
            <Switch>
                <Route exact path={"/"} component={WelcomePage}/>
                <Route exact path={"/welcome"} component={WelcomePage}/>
                <Route exact path={"/login"} component={LoginPage}/>
                <Route exact path={"/register"} component={RegisterPage}/>
                <Route exact path={"/home"} component={HomePage}/>
                <Route exact path={"/new-game"} component={GameSelectorPage}/>
                <Route exact path={"/generate-question-set/:config_id"} component={GenerateQuestionSet}/>
                <Route exact path={"/confirm-game/:config_id"} component={ConfirmGamePage}/>
                <Route exact path={"/solve-question-set/:question_set_id"} component={GameSolverSolveQuestionSet}/>
                <Route exact path={"/submit-results"} component={SubmitResultsPage}/>
                <Route exact path={"/view-results/:result_id"} component={ViewResultsPage}/>
                <Route exact path={"/highscore/:config_id"} component={HighScorePage}/>
                <Route exact path={"/highscore"} component={SelectConfigToViewHighScorePage}/>
            </Switch>
        </Router>
    )
}
