import {BackendBaseURL} from "../envvar";

export function GetNonCustomConfigurationAxiosConfig(jwtToken) {
    return {
        baseURL: BackendBaseURL,
        url: '/configuration/default',
        method: 'get',
        headers: {
            Authorization: `Bearer ${jwtToken}`
        }
    }
}