import {BackendBaseURL} from "../envvar";

export function GetHighScores(configID, timeframe, scoreType, jwtToken) {
    return {
        baseURL: BackendBaseURL,
        url: '/highscores',
        params: {
            config_id: configID,
            timeframe: timeframe,
            score_type: scoreType,
        },
        method: 'get',
        headers: {
            Authorization: `Bearer ${jwtToken}`
        },
    }
}