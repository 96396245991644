import {BackendBaseURL} from "../envvar";

export function AuthenticateUserAxiosConfig(username, password) {
    return {
        baseURL: BackendBaseURL,
        url: '/authenticate',
        method: 'post',
        data: {
            username: username,
            password: password,
        }
    }
}