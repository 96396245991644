import React, {useEffect, useState} from 'react'
import {GetConfigurationAxiosConfig} from "../../api/GetConfigurationAxiosConfig";
import {JWTTokenCookieName} from "../../cookies";
import axios from "axios";
import {useCookies} from "react-cookie";
import {browserHistory} from "../../browserhistory";

export function ConfirmGamePage(props) {
    const [cookies] = useCookies([JWTTokenCookieName])
    const configID = props.match.params.config_id
    const [config, setConfig] = useState(null)

    useEffect(() => {
        const config = GetConfigurationAxiosConfig(configID, cookies[JWTTokenCookieName])
        axios(config).then((response) => {
            setConfig(response.data)
        })
    }, [])

    return <>
        <img src={'/qm-logo-corner.svg'} className={'qm-logo-top-left'} onClick={() => browserHistory.push('/home')}/>

        <div className={'mg-t-150 question-solver-current-input'}>
            {config !== null ? config.number_of_questions : '...'}
        </div>

        <div className={'correct-answers-label txt-align-center'}>
            Number of Questions
        </div>

        <div className={'round-yellow-circle-center-container z-n1'}>
            <div className={'round-yellow-circle-center'}>

            </div>
        </div>

        <button className={'large-blue-button mg-t-55 mg-0-auto'} onClick={() => browserHistory.push(`/generate-question-set/${configID}`)}>Start!</button>
        <button className={'large-orange-button mg-t-55 mg-0-auto'} onClick={() => browserHistory.push('/home')}>Home</button>
    </>
}