import {BackendBaseURL} from "../envvar";

export function PostQuestionSetResult(questionSetID, configID, results, jwtToken) {
    const resultsToSend = []

    for (let i = 0; i < results.length; i++) {
        const aResult = results[i]

        resultsToSend.push({
            answer: Number(aResult.answer),
            time_to_answer_ms: aResult.timeToSolveMS,
        })
    }

    return {
        baseURL: BackendBaseURL,
        url: '/result',
        method: 'post',
        headers: {
            Authorization: `Bearer ${jwtToken}`
        },
        params: {
            config_id: configID,
            question_set_id: questionSetID,
        },
        data: {
            results: resultsToSend,
        }
    }
}